import { useState } from "react"
import { Button, Card, Dialog, DialogActions, DialogContent, Box, Grid, TextField, IconButton, ButtonBase } from "@material-ui/core"
import { CloseOutlined, CloudDownloadOutlined, CopyOutlined, ReloadOutlined } from '@ant-design/icons';
import SyntaxHighlighter from 'react-syntax-highlighter'
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { send_command } from "components/api";
import { ResultNotification } from "components/ResultNotification";


export const AppClient = () => {
    const [clientId, setClientId] = useState('')
    const [clientSecret, setClientSecret] = useState('')
    const [authSecret, setAuthSecret] = useState('')
    const [show, setShow] = useState(false)

    const decode = () => {
        const secret = window.atob(authSecret).split(':')[1]
        setClientSecret(secret)
        const id = window.atob(authSecret).split(':')[0]
        setClientId(id)
    }
    const encode = () => {
        const encoded = window.btoa(`${clientId}:${clientSecret}`)
        setAuthSecret(encoded)
    }
    const getConfig = () => {
        setShow(true)
    }

    const ViewCode = ({ open, handleClose, data }) => {
        const download = () => {
            const blob = new Blob([data + `COGNITO_AUTH_SECRET=${authSecret}`], { type: "text/plain" });
            const url = URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.download = `demo.env`;
            link.href = url;
            link.click();
        }
        return (
            <Dialog open={open} >
                <DialogActions>
                    <Button variant="contained" onClick={() => { navigator.clipboard.writeText(data + `COGNITO_AUTH_SECRET=${authSecret}`) }}><CopyOutlined /></Button>&nbsp;
                    <Button variant="contained" onClick={download}><CloudDownloadOutlined /></Button>
                    <IconButton aria-label="close" onClick={handleClose} ><CloseOutlined /></IconButton>
                </DialogActions>
                <DialogContent>
                    <Box sx={{ fontFamily: 'Monospace' }}>
                        <SyntaxHighlighter language="json" style={docco}>
                            {data + `COGNITO_AUTH_SECRET=${authSecret}`}
                        </SyntaxHighlighter>
                    </Box>
                </DialogContent>
            </Dialog>
        );
    }

    return (
        <>
            <Grid container>
                <Grid item xs={3} style={{ padding: '1em' }}>
                    <TextField value={clientId} label="AppClientId" onChange={(e) => setClientId(e.target.value)} fullWidth={true} />&nbsp;
                </Grid>
                <Grid item xs={9} style={{ padding: '1em' }}>
                    <TextField value={clientSecret} label="AppClientSecret" onChange={(e) => setClientSecret(e.target.value)} fullWidth={true} />
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Button variant="contained" color="primary" onClick={decode}>decode ^</Button>&nbsp;
                <Button variant="contained" color="primary" onClick={encode}>encode v</Button>
            </Grid>
            <Grid item xs={12} style={{ padding: '1em' }}>
                <TextField value={authSecret} fullWidth={true} label="CognitoAuthSecret" in onChange={(e) => setAuthSecret(e.target.value)} />
            </Grid>
            <Button variant="contained" onClick={getConfig}>Get config</Button>
            <ViewCode open={show} handleClose={() => setShow(false)} data={demoenv} />
        </>
    )
}

export const AppClientCommands = ({ tenant_id }) => {
    const [error, setError] = useState()
    const [currentCommand, setCurrentCommand] = useState({})
    const sync_spids = () => {
        send_command('ADMIN_SET_CLIENT_COMMAND', { 'tenant_id': tenant_id, 'command': "SYNCHRONIZE_SPIDS" })
            .then(re => alert("worked"))
            .catch(er => alert("failed"))
    }
    const updateEmails = () => {
        send_command('ADMIN_SET_CLIENT_COMMAND', { 'tenant_id': tenant_id, 'command': "UPDATE_EMAILS" })
            .then(re => alert("worked"))
            .catch(er => alert("failed"))
    }

    const getEmails = () => {
        send_command('ADMIN_SET_CLIENT_COMMAND', {'tenant_id': tenant_id, 'command': "GET_EMAILS"})
        .then(re => alert("worked"))
        .catch(er => alert("failed"))
    }

    const sync_tenant = () => {
        send_command('ADMIN_SET_CLIENT_COMMAND', {'tenant_id': tenant_id, 'command': "SYNCHRONIZE_TENANT"})
        .then(re => alert("worked"))
        .catch(er => alert("failed"))
    }

    const refresh = () => {
        send_command('ADMIN_GET_CURRENT_CLIENT_APP_COMMAND', { tenant_id: tenant_id })
            .then(res => setCurrentCommand(res))
            .catch(er => setCurrentCommand(er.message))
    }
    return (<>
        <Grid container>
            <Grid item xs={6}>
                <Button size="sm" variant="contained" onClick={sync_spids}>SyncSpids</Button>&nbsp;
                <Button size="sm" variant="contained" onClick={updateEmails}>UpdateEmail</Button>&nbsp;
                <Button size="small" variant="contained" onClick={getEmails}>GetEmails</Button>&nbsp;
                <Button size="small" variant="contained" onClick={sync_tenant}>SyncTenant</Button>                
                <ResultNotification initial_error={"bam"} />
            </Grid>
            <Grid item xs={6}>
                <Grid container>
                    <Grid item xs={12}>
                        <Button variant="contained" onClick={refresh}><ReloadOutlined /></Button>
                    </Grid>
                    {/* <Grid item xs={12}>
                        <pre>{JSON.stringify(currentCommand, null, 3)}</pre>
                    </Grid> */}
                </Grid>
            </Grid>
        </Grid>
    </>)
}


const demoenv = `EMAIL_HOST=email-smtp.us-west-2.amazonaws.com
EMAIL_PORT=587
EMAIL_USERNAME=AKIA6HKOYGVODCBJDHVA
EMAIL_PASSWORD=BIV9Sed+KPKKFIQcxIqYlvLqR3NShpp1pRmxevWCnGQr
EMAIL_FROM=info@safeportersecure.com
EMAIL_NAME=SafePorter

COGNITO_REGION=us-west-2
COGNITO_USER_POOL_ID=us-west-2_4UzNVeUwX
COGNITO_CLIENT_ID=4sg8s8upopju5op5bhvv8mqb46
COGNITO_ENDPOINT_NAME=safedata
COGNITO_ENDPOINT=https://signoffapi.demo.ourdataprotected.com


COGNITO_SERVICE_URL=https://demousers-ourdata.auth.us-west-2.amazoncognito.com/oauth2/token
HEARTBEAT_INTERVAL=60s
NEXT_PUBLIC_LANGUAGE=en-us
NODE_ENV=developmnet
PORT=8080

# INTEGRATION_INTERVAL=20s
INTEGRATION_CRON="*/1 * * * *"
`