import { Box, Button, Card, CardContent, CardHeader, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, TextField, Typography } from "@material-ui/core"
import { send_command } from "components/api"
import { useContext, useEffect, useState } from "react"
import { CardContext } from "./DashboardCard"
import { ReloadOutlined } from "@ant-design/icons"
import { Stack } from "@mui/system"
import { Field, Form, Formik } from "formik"


export const ClientApps = ({ tenant_id, collapsed_size }) => {
    const [loading, setLoading] = useState(false)
    const [clients, setClients] = useState([])
    const [error, setError] = useState()
    const [size, setSize] = useState(collapsed_size)
    const [lastHeartbeat, setLastHeartbeat] = useState(null)
    const condenced = useContext(CardContext)
    const [showNewClient, setShowNewClient] = useState(false)
    const fetch = () => {
        setLoading(true)
        send_command("ADMIN_GET_APP_CLIENTS", { tenant_id })
            .then(db_clients => {
                let last_hb = null
                db_clients.map(db_client => {
                    if (db_clients?.last_heartbeat) {// > last_hb) {
                        last_hb = db_clients.last_heartbeat
                    }
                }
                )

                // send_command("ADMIN_LIST_USER_POOL_CLIENTS", { tenant_id })
                //     .then(client_keys => {
                //         const client_secrets = {}

                //         client_keys.map(client_key => {
                //             client_secrets[client_key.ClientId] = client_key['ClientSecret']
                //         })
                //         const complete = db_clients.map(client => ({ ...client, secret: client_secrets[client['app_client_id'] ]}))
                //         setClients(complete)
                //         setLoading(false)

                //     })
                //     .catch(er => {
                //         setLoading(false)
                //         setError(er)
                //     })
                if (last_hb) setLastHeartbeat(last_hb)
                setClients(db_clients)
                setLoading(false)
            })
            .catch(er => {
                setLoading(false)

                setError(er)
            })
    }

    useEffect(() => {
        fetch()
    }, [])

    if (error) {
        return (<>{error.message}</>)
    }

    const newClient = () => {
        setShowNewClient(true)
    }
    const timeDiff = (heartbeatString) => {

        //const hb = Date.parse('2024-04-19T01:11:25.199211' + 'Z')
        const hb = Date.parse(heartbeatString + 'Z')
        const rightnow = new Date()
        const diff = rightnow - hb
        const hours = Math.round((diff / (60 * 60 * 1000)) % 60)
        const mins = Math.round((diff / (60 * 1000)) % 60)
        const secs = Math.round((diff / 1000) % 60)
        return `${hours}:${String(mins).padStart(2, '0')}:${String(secs).padStart(2, '0')}`
    }

    return (
        <Grid item xs={12}>

            {loading && <>loading...</>}
            {!loading && <>
                {condenced ?
                    <Grid container>
                        <Grid item xs={12}>
                            There are {clients.length} AppClients for this tenant<br />
                            Last Heartbeat: {clients && timeDiff(clients[0]?.last_heartbeat)} ago
                        </Grid>
                    </Grid> :
                    <Grid container>
                        <Grid item xs={12}>
                            <IconButton aria-label="refresh" size="small" onClick={fetch} ><ReloadOutlined /></IconButton>&nbsp;
                            <Button variant="contained" onClick={newClient}>New</Button>
                        </Grid>
                        {clients.map(client => {
                            return (
                                <ClientCard client={client} timeDiff={timeDiff} />
                            )
                        })}
                    </Grid>}
                <NewClientDialog tenant_id={tenant_id} open={showNewClient} setOpen={setShowNewClient} />
            </>}
        </Grid >

    )

}

const ClientCard = ({client, timeDiff}) => {

    const [secret, setSecret] = useState()

    const test = (key) => {
        send_command("ADMIN_GET_APP_CLIENT_SECRET", { "app_client_id": key })
            .then((res) => {
                setSecret(res.key)
                setInterval(() => {
                    setSecret("***")
                }, 10000)
            }).catch(() => {setSecret("could not decode")})
    }
    return (
        <Grid item xs={12} lg={6}>
            <Card key={client.client_app_id} style={{ margin: "1em" }}>
                <CardHeader title={client.tenant} />
                <CardContent>
                    <Typography>
                        Client ID: {client.app_client_id}
                    </Typography>
                    <Typography>
                        Last Heartbeat: {client.last_heartbeat}
                    </Typography>
                    <Typography>
                        Last Heartbeat: {client && timeDiff(client.last_heartbeat)} ago
                    </Typography>
                    <Typography >
                        Secret: {secret}
                    </Typography>
                    <Button variant="contained" onClick={() => test(client.app_client_id)}>Get Secret</Button>
                </CardContent>
            </Card>
        </Grid>
    )
}
const NewClientDialog = ({ tenant_id, open, setOpen }) => {
    const initialValues = {
        name: ""
    }
    const save = (fields) => {
        send_command("AUTH_CREATE_APP_CLIENT", { "tenant_id": tenant_id, "app_client_name": fields.name })
            .then(setOpen(false))
            .catch(err => {
                alert(err.message)
                console.log(err)
            })
    }
    const close = () => {
        setOpen(false)
    }
    return (
        <Dialog open={open} onClose={() => setOpen(!open)}>
            <DialogTitle><Typography variant="subtitle1">New AppClient (key)</Typography></DialogTitle>
            <Formik
                initialValues={initialValues}
                onSubmit={save}
            ><Form>

                    <DialogContent>
                        <Field as={TextField} fullWidth={true} variant="outlined" label="Name" id="name" name="name" />
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" color="primary" type="submit">Create</Button>&nbsp;<Button variant="contained" color="secondary" onClick={close}>Cancel</Button>
                    </DialogActions>

                </Form>
            </Formik>
        </Dialog>
    )

}