import {
    Badge,
    Grid,
    Button,
    Switch,
    Typography,
    makeStyles
} from '@material-ui/core'
import React, { useState } from 'react'
import { useQueryClient } from 'react-query'

import {
    AddUserModal,
    AdminTable,
    EditUserModal,
    UserInfoModal
} from '../../components/admin'

import {
    ADMIN_TENANT_GET,
    useDeleteUserMutation,
    useEnableUserMutation,
    useTenantQuery,
    useUserTableQuery
} from 'components/api'

import { BackdropProgress } from '../../components'

import {
    CreateOutlined as EditIcon,
    DeleteOutlined as DeleteIcon,
    PersonAddOutlined as CreateNewIcon,
    VisibilityOutlined as ViewIcon,
    Check
} from '@material-ui/icons'

const useStyles = makeStyles(theme => ({
    titleContainer: {
        paddingBottom: 24
    },
    addButtonContainer: {
        textAlign: 'end',
        [theme.breakpoints.down('xs')]: {
            textAlign: 'start',
            marginTop: 8
        }
    },
    badgeContainer: {
        paddingLeft: 16
    },
    typeUser: {
        backgroundColor: '#ebbf44',
        color: 'white'
    },
    button: {
        marginRight: 4,
        color: '#fff'
    },
    icon: {
        marginRight: 8
    }
}))

function BadgeFormatter() {
    const classes = useStyles()
    return (
        <Grid container className={classes.badgeContainer}>
            <Badge
                badgeContent={'User'}
                classes={{
                    badge: classes.typeUser
                }}
            />
        </Grid>
    )
}

function setUserEnabledInQueryData(queryClient, user, enabled) {
    const userData = queryClient.getQueryData(['user-data'])

    const record = userData.find(x => x.sub === user.sub)

    if (record) {
        record.Enabled = enabled
    }

    queryClient.setQueryData(['user-data'], userData)
}

function SwitchFormatter({ value }) {
    const queryClient = useQueryClient()

    const { mutateAsync: enableUserAsync, isLoading } = useEnableUserMutation()

    const onChangeEnabled = React.useCallback(
        async e => {
            e.stopPropagation()
            e.preventDefault()

            const enabled = !e.target.checked

            enableUserAsync({
                    email: value.email,
                    enabled
            }).then(() => {
                queryClient.invalidateQueries('user-data')
                console.warn("done")
            })
        },
        [queryClient, value, enableUserAsync]
    )

    return (
        <Grid container>
            <Switch
                checked={value.Enabled}
                onClick={onChangeEnabled}
                size="small"
                disabled={isLoading}
            />
        </Grid>
    )
}

function ActionFormatter({ value }) {
    const editUserDialogRef = React.useRef()
    const { mutateAsync: deleteUserAsync } = useDeleteUserMutation()
    const queryClient = useQueryClient()

    const onDeleteUser = React.useCallback(
        async e => {
            e.preventDefault()

            if (!window.confirm('Are you sure you want to delete?')) {
                return
            }
            try {
                await deleteUserAsync({ email: value.email })

                await queryClient.invalidateQueries('user-data')
            } catch (error) {
                throw error
            }
        },
        [value, deleteUserAsync, queryClient]
    )

    const classes = useStyles()

    return (
        <div>
            <Button
                onClick={() => {
                    editUserDialogRef.current.open(value)
                }}
                variant="contained"
                color="secondary"
                className={classes.button}
            >
                <EditIcon fontSize="small" />
            </Button>
            <Button
                onClick={onDeleteUser}
                variant="contained"
                color="secondary"
                className={classes.button}
                disabled={value.Enabled}
            >
                <DeleteIcon fontSize="small" />
            </Button>

            <EditUserModal ref={editUserDialogRef} />
        </div>
    )
}

function UserInfo({ user }) {
    const viewUserDialogRef = React.useRef()
    const classes = useStyles()
    return (
        <div>
            <Button
                onClick={() => viewUserDialogRef.current.open(user)}
                variant="contained"
                color="secondary"
                className={classes.button}
            >
                <ViewIcon fontSize='small' />
            </Button>
            <UserInfoModal ref={viewUserDialogRef} />
        </div>
    )
}

export default function ManageUsers(tenant_id) {
    const { data: userData, isLoading: userLoading } = useUserTableQuery()
    const [tenantLoading, setTenantLoading] = useState(false)
    const [tenantData, setTenantData] = useState({})
    //const { data: tenantData, isLoading: tenantLoading } = useTenantQuery()
    const addUserDialogRef = React.useRef()

    const columnData = [
        {
            field: 'given_name',
            headerName: 'Name',
            flex: 1,
            sortable: true
        },
        { field: 'email', headerName: 'Email', flex:1, sortable: true },
        {
            field: 'isAdmin',
            headerName: 'Admin',
            width: 100,
            sortable: true,
            renderCell: ({row}) => <>{row['custom:is_admin'] && <Check/>}</>
        },
        {
            field: 'isSuperAdmin',
            headerName: 'Super Admin',
            width: 140,
            sortable: true,
            renderCell: ({row}) => <>{row['custom:is_super_admin'] && <Check/>}</>
        },
        {
            field: 'user_info',
            headerName: 'User Info',
            width: 120,
            sortable: true,
            renderCell: ({ row }) => <UserInfo user={row} />
        },
        {
            field: 'Enabled',
            headerName: 'Enabled',
            width: 120,
            sortable: true,
            renderCell: ({ row }) => <SwitchFormatter value={row} />
        },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 160,
            sort: false,
            renderCell: ({ row }) => <ActionFormatter value={row} />
        }
    ]

    const classes = useStyles()

    if (userLoading || tenantLoading) {
        return <BackdropProgress />
    }

    const fetch = () => {
        ADMIN_TENANT_GET(tenant_id).then( res => {
            setTenantData(res)
            console.log({res})
        }).catch(er => {
            console.error({er})
            alert(er.message)
        })
    }
    return (
        <Grid container spacing={4}>
            {/* <Button onClick={fetch} variant="contained"  color="primary" size="small">Fetch</Button> */}
            <Grid item container xs={12}>
                <Grid item container xs={12} className={classes.titleContainer}>
                    {/* <Grid item xs={12} sm={6}>
                        <Typography variant="h5" gutterBottom>
                        {tenantData?.tenant_name}Users
                        </Typography>
                    </Grid> */}
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        className={classes.addButtonContainer}
                    >
                        <Button
                            onClick={() => {
                                addUserDialogRef.current.open()
                            }}
                            variant="contained"
                            color="secondary"
                            className={classes.button}
                        >
                            <CreateNewIcon
                                fontSize="small"
                                className={classes.icon}
                            />
                            New User
                        </Button>
                    </Grid>
                </Grid>
                <Grid item container xs={12}>
                    <AdminTable columnData={columnData} rowData={userData} />
                </Grid>
            </Grid>
            <AddUserModal ref={addUserDialogRef} />
        </Grid>
    )
}
