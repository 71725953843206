
// project import
import { ADMIN_SPID_GET, send_command, useEditUserMutation } from '../../components/api'
import { useEffect, useState } from 'react';
import { Button, Card, Container, Grid, Typography } from '@material-ui/core'

const SpidsPage = ({tenant_id}) => {
  const [loading, setLoading] = useState(false)
  const [spidStats, setSpidStats] = useState({})
  const [spidResult, setSpidResult] = useState(null);
  const [spid, setSpid] = useState();
  const [error, setError] = useState(null)
  const handleChange = event => {
    setSpid(event.target.value);
  }

  const findSPID = () => {
      // Reset the Spid results view.
      setSpidResult(null);
      setLoading(true)
      ADMIN_SPID_GET(spid)
      .then((spid) => {
        setSpidResult(spid);
        setLoading(false)
      }).catch((error) => {
        console.log(error);
        setError(`Failed to load spid. Call to API failed or Spid '${spid}' does not exist.`)
        setLoading(false)
      })
  }
  const getSpidStats = () => {
    send_command("ADMIN_SPID_STATES", {"tenant_id": tenant_id})
    .then(stats => setSpidStats(stats))
    .catch(err => setError(err.message))
  }
  useEffect(() => {
    getSpidStats()
  }, [])
  return (
    <Container>
      <Grid container spacing={3}>
        <Grid items xs={12}>
          <Typography>
            Created: {spidStats['Created']}, Invited: {spidStats['Invited']}, Responded: {spidStats['Responded']}, Disabled: {spidStats['Disabled']}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <input 
                type="text"
                id="spid"
                name="spid"
                onChange={handleChange}
                value={spid}
            />
            <Button color='primary' onClick={findSPID}>Look Up SPID</Button>
            <div style={{visibility: error != null? 'visible': 'collapse'}} >
              {error}
            </div>
            <div style={{visibility: spidResult != null ? 'visible' : 'collapse'}}>
              <div>
                <label>Tenant Id: { spidResult?.tenant_id }</label>
              </div>
              <div>
                <label>Sp Id: { spidResult?.sp_id }</label>
              </div>
              <div>
                <label>Sort Key: { spidResult?.sk}</label>
              </div>
              <div>
                <label>Last Response Time: { spidResult?.last_response_time }</label>
              </div>
              <div>
                <label>Created Time: { spidResult?.created_time}</label>
              </div>
              <div>
                <label>State: { spidResult?.state }</label>
              </div>
            </div>
          
          </Card>
        </Grid>
      </Grid>

    </Container>
  )
};

export default SpidsPage;