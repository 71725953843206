import { Button, Card, CardContent, CardHeader, Chip, Grid, TextField, Tooltip, Typography } from "@material-ui/core"
import axios from "axios"
import { send_command } from "components/api"
import { useEffect, useState } from "react"

const accounts = {
    "977807095132": "Dev",
    "629078184297": "ProdUS",
    "942866997229": "DemoUS",
    "795187495424": "DevRec",
    "340723061215": "EU"
}
export const Receivers = () => {
    const [receivers, setReceviers] = useState([])
    const load = () => {
        const recs = []
        send_command("ADMIN_GET_RECEIVERS", {})
            .then(res => {
                res.map(rec => recs.push({ "url": rec }))
                setReceviers(recs)
            })
            .catch(ex => alert(ex.message))
    }

    useEffect(() => {
        load()
    }, [])
    return (
        <Grid container>
            <Grid item xs={12}>
                <Typography >Survey Receiver Status</Typography>
            </Grid>
            <Button variant="contained" color="primary" onClick={load}>Refresh</Button>

            {
                receivers.map(rec => {                    
                    return (<Receiver  key={rec.url} receiver={rec} />)
                })
            }
        </Grid>)
}

const Receiver = ({ receiver }) => {
    const [state, setState] = useState({ "status": "untested" })
    const check = () => {
        axios.get(receiver.url + "/healthcheck")
            .then(res => { setState(res.data) })
            .catch(setState({ "status": "fail" }))
    }
    const colorForState = (state) => {
        switch (state) {
            case 'success':
                return 'lightgreen'
            case 'untested':
                return "primary"
            case 'fail':
                return "red"
        }
    }
    useEffect(() => {
        check()
    }, [])
    return (
        <Grid item xs={12} sm={3}>
            <Card style={{ margin: "1em", backgroundColor: colorForState(state.status) }}>
                <CardContent>
                    <Typography>{receiver.url}</Typography>
                    <Tooltip title={JSON.stringify(state)}>
                        <div>
                            <Chip size="small" label={state.status} />
                            <Chip size="small" label={state.region} />
                            <Chip size="small" label={accounts[state.accountId]} />
                        </div>
                    </Tooltip>
                </CardContent>
            </Card>
        </Grid>
    )
}