import React, { useEffect, useRef, useState } from 'react'
import { Box, Grid, Typography } from '@material-ui/core'
import {  Wrapper } from 'components'

import styles from '../../assets/scss/app.module.scss'
import {
    Week,
    WeekPercent,
    SfProgressBar,
    NumHeading,
    Heading,
    CardOne,
    ProgressBox,
    TimeGrid
} from 'assets/globalComponent'
import {
    ChartMainGrid,
    DiversityPage,
    Exportbtn,
    OverviewGrid
} from 'assets/diversityStyle'
import { Card, CardContent } from '@material-ui/core/'
import CardHeader from '@material-ui/core/CardHeader'
import { ReactComponent as LaunchIcon } from '../../components/assets/launch-icon.svg'
import DonutChart from 'components/charts/DonutChart'
import { useSelector } from 'react-redux'
import Loader from 'components/loader'
import ColumnChart from 'components/charts/columnChart'
import DeclineAnswersChart from 'components/charts/DeclinedAnswersChartNew'
import HiringDiversityChart from 'components/charts/MultilineChartNew'
import InsightsCard from 'components/charts/InsightChart'
import ColumnGroupChart from 'components/charts/GroupColumn2dChart'
import StackBarChart from 'components/charts/StackBarsChart'
import InsightsMultiCard from 'components/charts/InsightMultiChart'
import { Margin, usePDF } from 'react-to-pdf'

export default function Diversity() {
    const {
        tenantStatsData: statsData,
        isLoadingTenantStats: isLoadingStats,
        pieData,
        selectedCountry,
        selectedRegion,
        declinedAnswerData,
        employeeDiversityData,
        hiringDiversityData,
        drilldownsHierarchy
    } = useSelector(state => state.tenantStats)
    const [position, setPosition] = useState({})
    const [loading, setLoading] = useState(false)

    const chartMainGridRef = useRef(null)

    const { toPDF, targetRef } = usePDF({
        method: 'save',
        filename: 'graphData.pdf',
        page: { margin: Margin.MEDIUM }
    })
    const { brandingData, isLoadingBranding } = useSelector((state) => state.branding)
    const surveyData = statsData?.diversity?.surveys
    const hide = statsData?.diversity?.hide
    const percentNewHiresCompleted =
        (surveyData?.new_hires_completed * 100) / surveyData?.surveys_issued
    const percentExistingEmployeesHires =
        (surveyData.exist_employee_complete * 100) / surveyData.surveys_issued

    useEffect(() => {
        if (Object.values(selectedCountry).length === 0) {
            setPosition({})
        }
    }, [selectedCountry])

    if (isLoadingStats) {
        return <Loader />
    }

    const handleDownload = async () => {
        try {
            setLoading(true)
            await exportAsync()
            setLoading(false)
        } catch (error) {
            setLoading(false)
        }
    }

    const exportAsync = () => {
        toPDF()
        return new Promise(resolve => {
            setTimeout(resolve, 2000) // Simulating a 2-second delay for await set Loading false
        })
    }
    if (!surveyData) {
        return (<>No Diversity Dashboard data is available at this time</>)
    }
    return (
        <> {hide ? <>
            <Wrapper
                isPrinting={false}
                handlePrint={() => {}}
                title="Diversity Survey"
                variant="h5"
                palette={{
                    title: brandingData.pages.inclusionFeedback.pageHeaderText
                }}
            >
                No diversity survey data has been processed at this time.
            </Wrapper>

        </> : <>
            <Grid
                container
                item
                xs={12}
                justifyContent="flex-end"
                alignItems="flex-start"
            >
                <Exportbtn
                    variant="outlined"
                    alt="Export dashboard"
                    onClick={handleDownload}
                    disabled={loading}
                    style={{ color: 'white' }}
                >
                    {loading ? 'Exporting...' : 'Export Dashboard'}
                </Exportbtn>
            </Grid>
            <DiversityPage ref={targetRef}>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={6}>
                        <Box
                            className={`${styles.PageHeading} ${styles.Diversity}`}
                        >
                            <Typography variant="h1">
                                Diversity Survey
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
                <Box>
                    <Heading variant="h3" component="h3">
                        Employee Survey Completion
                    </Heading>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={8}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Card className={styles.CardOne}>
                                        <CardContent
                                            className={styles.CardOneContent}
                                        >
                                            <Grid container spacing={2}>
                                                {/* Need data from Api for display Survey Response Times  */}
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={12}
                                                    md={4}
                                                >
                                                    <Heading variant="h6">
                                                        Surveys Issued
                                                    </Heading>
                                                    <NumHeading
                                                        variant="h2"
                                                        component="h2"
                                                    >
                                                        {
                                                            surveyData?.surveys_issued
                                                        }
                                                    </NumHeading>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={12}
                                                    md={4}
                                                >
                                                    <Heading variant="h6">
                                                        Surveys Completed
                                                    </Heading>
                                                    <NumHeading
                                                        green
                                                        variant="h2"
                                                        component="h2"
                                                    >
                                                        {
                                                            surveyData?.surveys_completed
                                                        }
                                                    </NumHeading>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={12}
                                                    md={4}
                                                >
                                                    <Heading variant="h6">
                                                        {' '}
                                                        Surveys Overdue
                                                    </Heading>
                                                    <NumHeading
                                                        red
                                                        variant="h2"
                                                        component="h2"
                                                    >
                                                        {
                                                            surveyData?.surveys_overdue
                                                        }
                                                    </NumHeading>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <CardOne>
                                        <CardHeader
                                            titleTypographyProps={{
                                                variant: 'h4',
                                                component: 'h4'
                                            }}
                                            title="Surveys Completed"
                                        />
                                        <CardContent>
                                            <ProgressBox>
                                                <Grid
                                                    container
                                                    spacing={0}
                                                    alignItems="center"
                                                >
                                                    <Grid item xs="auto">
                                                        <Typography
                                                            variant="h3"
                                                            component="h3"
                                                        >
                                                            {
                                                                `${((surveyData?.surveys_completed / surveyData?.surveys_issued) * 100).toFixed(0)}%`
                                                            }
                                                        </Typography>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        style={{ flexGrow: 1 }}
                                                    >
                                                        <SfProgressBar
                                                            variant="determinate"
                                                            value={
                                                                (surveyData?.surveys_completed / surveyData?.surveys_issued) * 100
                                                            }
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </ProgressBox>
                                        </CardContent>
                                    </CardOne>
                                </Grid>
                                {/* <Grid item xs={12} sm={6}>
                                    <CardOne>
                                        <CardHeader
                                            titleTypographyProps={{
                                                variant: 'h4',
                                                component: 'h4'
                                            }}
                                            title="New Hire Surveys"
                                        />
                                        <CardContent>
                                            <ProgressBox>
                                                <Grid
                                                    container
                                                    spacing={0}
                                                    alignItems="center"
                                                >
                                                    <Grid item xs="auto">
                                                        <Typography
                                                            variant="h3"
                                                            component="h3"
                                                        >
                                                            {
                                                                percentNewHiresCompleted
                                                            }
                                                            %
                                                        </Typography>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        style={{ flexGrow: 1 }}
                                                    >
                                                        <SfProgressBar
                                                            variant="determinate"
                                                            value={
                                                                percentNewHiresCompleted
                                                            }
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </ProgressBox>
                                        </CardContent>
                                    </CardOne>
                                </Grid> */}
                                <Grid item xs={12} sm={12}>
                                    <CardOne>
                                        <CardHeader
                                            titleTypographyProps={{
                                                variant: 'h4',
                                                component: 'h4'
                                            }}
                                            title="Surveys Overdue"
                                        />
                                        <CardContent>
                                            <ProgressBox>
                                                <Grid
                                                    container
                                                    spacing={0}
                                                    alignItems="center"
                                                >
                                                    <Grid item xs="auto">
                                                        <Typography
                                                            variant="h3"
                                                            component="h3"
                                                        >
                                                            {
                                                                `${(((surveyData?.surveys_issued - surveyData?.surveys_completed) / surveyData.surveys_issued) * 100).toFixed(0)}%`
                                                            }
                                                        </Typography>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        style={{ flexGrow: 1 }}
                                                    >
                                                        <SfProgressBar
                                                            variant="determinate"
                                                            value={
                                                                ((surveyData?.surveys_issued - surveyData?.surveys_completed) / surveyData.surveys_issued) * 100
                                                            }
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </ProgressBox>
                                        </CardContent>
                                    </CardOne>
                                </Grid>
                                {/* <Grid item xs={12} sm={6}>
                                    <CardOne>
                                        <CardHeader
                                            titleTypographyProps={{
                                                variant: 'h4',
                                                component: 'h4'
                                            }}
                                            title="Existing Employees Surveys"
                                        />
                                        <CardContent>
                                            <ProgressBox>
                                                <Grid
                                                    container
                                                    spacing={0}
                                                    alignItems="center"
                                                >
                                                    <Grid item xs="auto">
                                                        <Typography
                                                            variant="h3"
                                                            component="h3"
                                                        >
                                                            {
                                                                percentExistingEmployeesHires
                                                            }
                                                            %
                                                        </Typography>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        style={{ flexGrow: 1 }}
                                                    >
                                                        <SfProgressBar
                                                            variant="determinate"
                                                            value={
                                                                percentExistingEmployeesHires
                                                            }
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </ProgressBox>
                                        </CardContent>
                                    </CardOne>
                                </Grid> */}
                            </Grid>
                        </Grid>
                        {/* Need data from Api for  display dynamic values of Survey Response Times */}
                        <Grid item xs={12} md={4}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <CardOne>
                                        <CardHeader
                                            titleTypographyProps={{
                                                variant: 'h4',
                                                component: 'h4'
                                            }}
                                            title="Survey Response Times"
                                        />
                                        <CardContent>
                                            <TimeGrid>
                                                {surveyData?.surveyResponseTime?.response_times.labels.map(
                                                    (label, index) => (
                                                        <Grid
                                                            container
                                                            spacing={0}
                                                            justifyContent="center"
                                                        >
                                                            <Grid item xs={6}>
                                                                <Week>
                                                                    {label}
                                                                </Week>
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xs={6}
                                                                align="right"
                                                            >
                                                                <WeekPercent>
                                                                    {
                                                                        surveyData
                                                                            ?.surveyResponseTime
                                                                            ?.response_times
                                                                            ?.data[
                                                                        index
                                                                        ]
                                                                    }
                                                                    %
                                                                </WeekPercent>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <SfProgressBar
                                                                    variant="determinate"
                                                                    value={
                                                                        surveyData
                                                                            ?.surveyResponseTime
                                                                            ?.response_times
                                                                            ?.data[
                                                                        index
                                                                        ]
                                                                    }
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    )
                                                )}
                                            </TimeGrid>
                                        </CardContent>
                                    </CardOne>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Heading variant="h3" component="h3">
                        Employee Survey Responses
                    </Heading>
                    <ChartMainGrid className="ChartMainGrid">
                        {Object.keys(pieData).map(key => {
                            const item = pieData[key]
                            return (
                                <Grid
                                    container
                                    spacing={3}
                                    className={
                                        item?.insights.length
                                            ? 'TWoColumnGrid'
                                            : 'TWoColumnGrid'
                                    }
                                >
                                    <Grid
                                        item
                                        xs={12}
                                        md={12}
                                        sm={12}
                                        // lg={
                                        //     item?.insights?.length ||
                                        //         position[key]
                                        //         ? 4
                                        //         : 8
                                        // }
                                        lg={8}
                                    >
                                        <CardOne redbdr minheight hasborder>
                                            <CardHeader
                                                /* action={
                                                    <IconButton>
                                                        <LaunchIcon />
                                                    </IconButton>
                                                } */
                                                titleTypographyProps={{
                                                    variant: 'h4',
                                                    component: 'h4'
                                                }}
                                                title={item.caption}
                                            />
                                            <CardContent>
                                                {Object.keys(selectedRegion)
                                                    .length > 0 ||
                                                    Object.keys(selectedCountry)
                                                        .length > 0 ? (
                                                    item.caption ===
                                                        'Officer Position' ? (
                                                        'Regional data for this field not available due to privacy controls and identifiability considerations.'
                                                    ) : (
                                                        <StackBarChart
                                                            data={
                                                                Object.keys(
                                                                    selectedRegion
                                                                ).length > 0
                                                                    ? selectedRegion
                                                                    : selectedCountry
                                                            }
                                                            dataKey={key}
                                                            setPosition={
                                                                setPosition
                                                            }
                                                            drilldownsHierarchy={
                                                                drilldownsHierarchy
                                                            }
                                                            position={position}
                                                        />
                                                    )
                                                ) : (
                                                    <DonutChart
                                                        data={item}
                                                        chartRef={
                                                            chartMainGridRef
                                                        }
                                                    />
                                                )}
                                            </CardContent>
                                        </CardOne>
                                    </Grid>
                                    {/* Bar charts are not to be used for now.  */}
                                    {/* <Grid item xs={12} md={12} lg={4}>
                                        <CardOne
                                            redbdr
                                            minheight
                                            hasborder
                                            fixedheight
                                        >
                                            <CardHeader
                                                titleTypographyProps={{
                                                    variant: 'h4',
                                                    component: 'h4',
                                                }}
                                                title={`${`${item?.caption} Identity Diversity Index`}`}
                                            />
                                            {Object.keys(selectedRegion)
                                                .length > 0 ||
                                                Object.keys(selectedCountry)
                                                    .length > 0 ? (
                                                <ColumnGroupChart
                                                    data={
                                                        Object.keys(
                                                            selectedRegion
                                                        ).length > 0
                                                            ? selectedRegion
                                                            : selectedCountry
                                                    }
                                                    dataKey={key}
                                                />
                                            ) : (
                                                <ColumnChart
                                                    data={item?.indexDataSample}
                                                />
                                            )}
                                        </CardOne>
                                    </Grid> */}
                                    {Object.keys(selectedRegion).length ===
                                        0 && (
                                            <Grid
                                                item
                                                xs={12}
                                                sm={12}
                                                md={12}
                                                lg={4}
                                            >
                                                <InsightsCard
                                                    data={item.insights}
                                                    title={item?.caption}
                                                />
                                            </Grid>
                                        )}

                                    {Object.keys(selectedRegion).length > 0 && (
                                        <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={12}
                                            lg={4}
                                        >
                                            <InsightsMultiCard
                                                data={{
                                                    selectedRegion,
                                                    pupKey: key
                                                }}
                                                title={item?.caption}
                                            />
                                        </Grid>
                                    )}
                                </Grid>
                            )
                        })}
                    </ChartMainGrid>
                    <Heading variant="h3" component="h3">
                        Overview
                    </Heading>
                    <OverviewGrid>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={12} md={12} lg={4}>
                                <CardOne fixedheight>
                                    <CardHeader
                                        /* action={
                                            <IconButton>
                                                <LaunchIcon />
                                            </IconButton>
                                        } */
                                        titleTypographyProps={{
                                            variant: 'h4',
                                            component: 'h4'
                                        }}
                                        title="Hiring Diversity Overview"
                                    />
                                    <HiringDiversityChart
                                        data={employeeDiversityData}
                                    />
                                </CardOne>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={4}>
                                <CardOne fixedheight>
                                    <CardHeader
                                        /* action={
                                            <IconButton>
                                                <LaunchIcon />
                                            </IconButton>
                                        } */
                                        titleTypographyProps={{
                                            variant: 'h4',
                                            component: 'h4'
                                        }}
                                        title="Employee Population Diversity"
                                    />
                                    <HiringDiversityChart
                                        data={hiringDiversityData}
                                    />
                                </CardOne>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={4}>
                                <CardOne fixedheight>
                                    <CardHeader
                                        /* action={
                                            <IconButton>
                                                <LaunchIcon />
                                            </IconButton>
                                        } */
                                        titleTypographyProps={{
                                            variant: 'h4',
                                            component: 'h4'
                                        }}
                                        title="Overview of Declined Answers"
                                    />
                                    <CardContent>
                                        <DeclineAnswersChart
                                            data={declinedAnswerData}
                                        />
                                    </CardContent>
                                </CardOne>
                            </Grid>
                        </Grid>
                    </OverviewGrid>
                </Box>
            </DiversityPage>
        </>
        }
        </>
    )
}
