import * as React from 'react'
import { DataGrid } from '@material-ui/data-grid'
import { Grid, makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
    root: {
        width: '100%'
    }
})

export default function DataTable({ columnData, rowData, defaultSortedBy }) {
    function getRowId(row) {
        return row.Username || row.vendor_id || row.sp_id
    }

    const classes = useStyles()

    return (
        <Grid className={classes.root}>
            <DataGrid
                autoHeight={true}
                rows={rowData}
                columns={columnData}
                hideFooterSelectedRowCount={true}
                disableColumnMenu
                getRowId={row => getRowId(row)}
                
                rowsPerPageOptions={[10, 25, 50, 100]}
                sortModel={defaultSortedBy}
            />
        </Grid>
    )
}
