import {
    AppBar,
    Avatar,
    Button,
    Container,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemText,
    Toolbar,
    Typography,
    makeStyles
} from '@material-ui/core'
import { Menu as MenuIcon } from '@material-ui/icons'
import { Auth } from 'aws-amplify'
import clsx from 'clsx'
import React from 'react'
import { useLocation } from 'react-router-dom'
import FilterSelect from './FilterSelect'
import Footer from './Footer'
import Link from './Link'
import Logo from './Logo'
import { usePrintLayout } from './PrintContext'
import { useUserOptions } from './UserOptionsContext'
import styles from './Layout.styles'
import BackdropProgress from './BackdropProgress'
import { useDispatch, useSelector } from 'react-redux'
import { fetchTenantStats } from 'redux/slices/tenantStatsSlice'
import FilterCheckbox from './FilterCheckbox'
import { AppSideBar, Userfooter } from 'assets/globalComponent'
import '../assets/scss/custom.scss'
import AssignmentOutlinedIcon from '@material-ui/icons/Assignment'
import EngagementSurveyIcon from '@material-ui/icons/ThumbUp'
import SmsOutlinedIcon from '@material-ui/icons/SmsOutlined'
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import { useAuthentication } from './AuthenticationContext'

const useStyles = makeStyles(styles)

function Sidebar(params) {
    const { showAdmin, showSuperAdmin } = params
    const location = useLocation()
    const classes = useStyles()
    const authState = useAuthentication()
    const { given_name, family_name } = authState.attributes
    const user = {
        name: `${given_name || ''} ${family_name || ''}`.trim()
    }

    const onSignout = React.useCallback(() => {
        Auth.signOut()
    }, [])    
    return (
        <List
            component="div"
            className={`${classes.linkContainer} LinkContainer`}
        >
            <div>
                <ListItem
                    button
                    component={Link}
                    href="/"
                    selected={location.pathname === '/' ? true : false}
                    classes={{
                        root: classes.linkButton,
                        selected: classes.selected
                    }}
                >
                    <AssignmentOutlinedIcon />
                    Diversity Survey
                </ListItem>

                {location.pathname === '/' && <FilterCheckbox />}

                <ListItem
                    button
                    component={Link}
                    href="/engagement"
                    selected={
                        location.pathname === '/engagement' ? true : false
                    }
                    classes={{
                        root: classes.linkButton,
                        selected: classes.selected
                    }}
                >
                    <EngagementSurveyIcon />
                    Our Surveys
                </ListItem>

                <ListItem
                    button
                    component={Link}
                    href="/inclusion"
                    selected={location.pathname === '/inclusion' ? true : false}
                    classes={{
                        root: classes.linkButton,
                        selected: classes.selected
                    }}
                >
                    <SmsOutlinedIcon />
                    Inclusion Feedback
                </ListItem>
                <ListItem
                    button
                    component={Link}
                    href="/vendors"
                    selected={location.pathname === '/vendors' ? true : false}
                    classes={{
                        root: classes.linkButton,
                        selected: classes.selected
                    }}
                >
                    <ShoppingCartOutlinedIcon />
                    Vendor Survey
                </ListItem>           
                {showAdmin && (
                    <ListItem
                        button
                        component={Link}
                        href="/admin"
                        selected={location.pathname === '/admin' ? true : false}
                        classes={{
                            root: classes.linkButton,
                            selected: classes.selected
                        }}
                    >
                        <ListItemText>ADMIN HOME</ListItemText>
                    </ListItem>
                )}
                {showSuperAdmin && (
                    <ListItem
                    button
                    component={Link}
                    href="/superadmin"
                    selected={location.pathname === '/superadmin' ? true : false}
                    classes={{
                        root: classes.linkButton,
                        selected: classes.selected
                    }}
                >
                    <ListItemText>SUPER ADMIN</ListItemText>
                </ListItem>
                )}
            </div>
            <Userfooter>
                {user  && (
                    <>
                        <Typography>
                            <div className={classes.userContainer}>
                                <Avatar alt={user.name} src={user.avatar} />
                                <div className={classes.userName}>
                                    <ListItemText>{user.name}</ListItemText>
                                </div>
                            </div>
                        </Typography>
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={onSignout}
                            startIcon={<ExitToAppIcon />}
                        >
                            Sign out
                        </Button>
                    </>
                )}
            </Userfooter>
        </List>
    )
}

export default function Layout({
    children,
    defaultDrawerOpen = true,
    maxWidth = false,
    showAdmin = true,
    showSuperAdmin = true
}) {
    const { options } = useUserOptions()
    const location = useLocation()
    const dispatch = useDispatch()
    const { isPrinting } = usePrintLayout()
    // const userActionsMenu = React.useRef()
    const { brandingData, isLoadingBranding } = useSelector(
        state => state.branding
    )
    const { tenantStatsData: statsData } =
        useSelector(state => state.tenantStats)

    React.useEffect(() => {
        if (options?.filters?.length > 0) {
            dispatch(fetchTenantStats(options?.filters))
        }
    }, [options, dispatch])

    const [open, setOpen] = React.useState(defaultDrawerOpen)

    const onToggleDrawer = React.useCallback(() => {
        setOpen(!open)
    }, [open])

    /* const onOpenMenu = React.useCallback(
        e => {
            userActionsMenu.current && userActionsMenu.current.open(e)
        },
        [userActionsMenu]
    ) */

    const classes = useStyles()

    if (isPrinting) {
        return children
    }

    if (isLoadingBranding) {
        return <BackdropProgress />
    }

    /* if (isLoadingStats && options?.filters?.length === 0) {
        return <Loader />
    } */

    return (
        <>
            <div className={classes.root}>
                <AppSideBar
                    className={location.pathname === '/' ? 'FrontPage' : ''}
                >
                    <AppBar
                        position="absolute"
                        className={clsx(
                            classes.appBar,
                            open && classes.appBarShift
                        )}
                    >
                        <Toolbar
                            className={`${classes.toolbar} ${location.pathname === '/diversity'
                                    ? 'BgGreen'
                                    : ''
                                }${location.pathname === '/engagement'
                                    ? 'BgGreen'
                                    : ''
                                }`}
                        >
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={onToggleDrawer}
                                className={classes.menuButton}
                            >
                                <MenuIcon />
                            </IconButton>
                            {/* <IconButton
                                edge="end"
                                color="inherit"
                                aria-label="user actions"
                                onClick={onOpenMenu}
                                className={classes.userActionButton}
                            >
                                <UserActionsIcon />
                            </IconButton>
                            <DropdownMenu ref={userActionsMenu}>
                                <MenuItem onClick={onSignout}>
                                    Sign out
                                </MenuItem>
                            </DropdownMenu> */}
                        </Toolbar>
                    </AppBar>
                    <Drawer
                        variant="permanent"
                        classes={{
                            paper: clsx({
                                [classes.drawerPaper]: true,
                                [classes.drawerPaperClose]: !open
                            })
                        }}
                        open={open}
                    >
                        <div className={classes.toolbarIcon}>
                            <Logo brand={brandingData} condensed />
                        </div>

                        <div className={classes.sidebarBottom}>
                            <Sidebar showAdmin={showAdmin} showSuperAdmin={showSuperAdmin}/>
                            {location.pathname === '/diversity' && (
                                <FilterSelect
                                    options={options}
                                    data={statsData.diversity}
                                />
                            )}
                        </div>
                    </Drawer>
                </AppSideBar>
                <main
                    className={`${classes.content} ${location.pathname === '/diversity' ? 'BgGreen' : ''
                        }${location.pathname === '/engagement' ? 'BgGreen' : ''}`}
                >
                    <div className={classes.appBarSpacer} />
                    <Container
                        maxWidth={maxWidth}
                        className={classes.container}
                    >
                        {children}
                    </Container>
                    <Footer />
                </main>
            </div>
        </>
    )
}
